import React from "react";
import AboutUs from "../components/AboutUs";
import GetStarted from "../components/get-started/GetStarted";
import { Home } from "../components/Home";
import Pricing from "../components/Pricing";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Services from "../components/Services";
import TermAndCondition from "../components/TermAndConditions";

const LandingPageRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "get-started",
    element: <GetStarted />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "services",
    element: <Services />,
  },
  {
    path: "pricing",
    element: <Pricing />,
  },
  {
    path: "about-us",
    element: <AboutUs />,
  },
  {
    path: "terms-and-conditions",
    element: <TermAndCondition />,
  },
]

export default LandingPageRoutes;
