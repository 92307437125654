import { useState } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { LuPhoneCall } from 'react-icons/lu';
import { GrContact } from 'react-icons/gr';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const ContactWidget = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  
  const onChatMaximized = () => {
    setIsVisible(true);
    setTimeout(() => setIsAnimating(true), 10);
  }

  const onChatMinimized = () => {
    setIsAnimating(false);
    setTimeout(() => setIsVisible(false), 300);
  }

  return (
    <>
      <TawkMessengerReact
        propertyId={process.env.REACT_APP_TAWKTO_PROPERTY_ID}
        widgetId={process.env.REACT_APP_TAWKTO_WIDGET_ID}
        onChatMaximized={onChatMaximized}
        onChatMinimized={onChatMinimized}
      />
      {isVisible && (<div className="fixed bottom-4 right-24 z-50">
        <div className={`flex transition-all duration-300 ease-out ${isAnimating ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4 pointer-events-none'}`}>
          <a href="mailto:info@dispatchquick.com">
            <div className="bg-moderate-orange text-white rounded-full p-4 shadow-lg mr-2">
              <FaEnvelope className="text-2xl" />
            </div>
          </a>
          <a href="sms:2817909452">
            <div className="bg-moderate-orange text-white rounded-full p-4 shadow-lg mr-2">
              <GrContact className="text-2xl" />
            </div>
          </a>
          <a href="tel:2817909452" target="_blank" rel="noopener noreferrer">
            <div className="bg-moderate-orange text-white rounded-full p-4 shadow-lg">
              <LuPhoneCall className="text-2xl" />
            </div>
          </a>
          
        </div>
      </div>)}
    </>
  );
};

export default ContactWidget;
