import React, { useState } from 'react';
import { Menubar } from 'primereact/menubar';
import logo from '../../assets/images/QuickDispatch-logo.png';
import { BtnTransparent } from '../shared/Button';
import './Header.scss';
import { useNavigate, Link } from "react-router-dom";
import { useDispatchForm } from '../../contexts/DispatchFormProvider';
import { DispatchForm } from '../dispatch-form/DispatchForm';
import TranslateComponent from "./../TranslateComponent";
import { ourServiceTypes } from "../Services";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isDispatchFormVisible, showDispatchForm, hideDispatchForm } = useDispatchForm();
  const navigate = useNavigate();

  const servicesSubMenuItems = ourServiceTypes.map((service) => {
    return {...service, command: () => navigate(service.link), link: service.link };
  });

  const items = [
    {
      label: 'Get Started',
      command: () => navigate('/get-started#leadForm'),
      link: '/get-started#leadForm',
    },
    {
      label: 'Our Services',
      command: () => navigate('/services'),
      link: '/services',
      items: servicesSubMenuItems,
    },
    {
      label: 'About Us',
      command: () => navigate('/about-us'),
      link: '/about-us',
    }
  ];

  const handleNavigation = () => {
    showDispatchForm();
  };

  return (
    <header className="bg-[#161616] text-white shadow-md sticky w-full top-0 z-50">
      <div className="md:container mx-auto p-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Link onClick={ () => navigate('/') }>
              <img src={logo} alt="Quick Dispatch" className="md:h-24 xs:h-14" />
            </Link>
            <span className="text-xl font-bold"></span>
          </div>

          <div className="hidden md:flex flex-grow">
            <Menubar model={items} className="custom-menubar text-lg" />
          </div>

          <TranslateComponent className="mr-2" />
          <div className='hidden md:block md:pr-5'>
            <BtnTransparent
              className="md:text-lg"
              name='Contact Us'
              handleClick={ handleNavigation }
            />
          </div>
          <div className="hidden md:flex items-center space-x-4 text-lg">
            <a href="tel:2817909452" className="text-white underline-offset-2">(281) 790-9452</a>
          </div>
          <button
            className="md:hidden flex items-cente p-2"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <i className={`pi ${mobileMenuOpen ? 'pi-times' : 'pi-bars'} text-2xl`}></i>
          </button>
        </div>

        {mobileMenuOpen && (
          <div className="md:hidden bg-moderate-orange p-4">
            {items.map((menu) => (
              <div className="mt-4">
                <p onClick={() => { navigate(menu.link); setMobileMenuOpen(!mobileMenuOpen);}}>{menu.label}</p>
              </div>
            ))}
            <div className="mt-4">
              <p onClick={handleNavigation}>Contact Us</p>
            </div>
            <div className="mt-4">
              <a href="tel:2817909452">(281) 790-9452</a>
            </div>
          </div>
        )}
      </div>

      <DispatchForm
        isViewDialog={isDispatchFormVisible}
        handleModal={hideDispatchForm}
      />
    </header>
  );
};

export default Header;
